.menuItem {
	display: flex;
	align-self: center;
	position: relative;

	a {
		padding: 32px 17px;
		@mixin fontFamily font-heading;
		font-weight: 400;
		color: var(--dark-grey);
		align-self: center;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 0.8125rem;
		z-index: 1;

		&:hover {
			color: var(--yellow-orange);
		}

		&.isActive {
			color: var(--yellow-orange);
		}
	}
}

.isToggle {
	cursor: pointer;
	display: none;
}

@media (--tablet) {
	.closeContainer {
		align-items: center;
		display: flex;
		margin-right: 17px;
	}

	.menuItem {
		order: 2;
		text-align: center;
		display: none;
		width: 100%;
		@mixin fontFamily font-heading;
		font-weight: 400;

		&[data-toggled='toggled'] {
			display: flex;
			padding: 0;
			flex-shrink: 0;

			a {
				display: flex;
				color: var(--white);
				flex: 1;
				justify-content: flex-end;
				align-items: flex-end;
				padding: 17px 0;

				&:hover {
					color: var(--yellow-orange);
				}

				&.isActive {
					color: var(--yellow-orange);
				}
			}
		}

		&.isToggle {
			display: flex;
			order: 1;
			height: 100%;

			.toggleIcon,
			.closeText {
				display: none;
			}

			&[data-toggled='toggled'] {
				height: auto;
				padding: 0;

				& a {
					padding: 1.5em;
					text-align: right;

					&:hover {
						color: var(--yellow-orange);
					}

					.title {
						display: none;
					}
				}

				.toggleIcon {
					display: flex;
					height: 20px;
					width: 20px;
					margin-left: 5px;
				}

				.closeText {
					display: flex;
					font-size: 0.8rem;
					margin: 0;
					padding: 0;
					@mixin fontFamily font-body;
				}
			}
		}
	}
}
