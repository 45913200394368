.navigation {
	ul {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
		justify-content: space-between;
	}

	li:not(:last-child) {
		margin-bottom: 24px;
	}

	a {
		color: var(--white);
		@mixin fontFamily font-heading;
		font-size: 0.8125rem;
		font-weight: 400;
		text-decoration: none;
		text-transform: uppercase;
		transition: color 0.2s;

		&:hover {
			color: var(--yellow-orange);
		}
	}
}

@media (--mobile) {
	.navigation ul {
		flex-direction: column;
	}
}
