.subMenuItem {
	@mixin fontFamily font-heading;
	font-weight: 400;
	font-size: 0.8rem;
	margin-left: 12px;
	margin-right: 12px;
	list-style: none;

	a {
		text-decoration: none;
		color: var(--black-grey);

		&.active {
			color: var(--yellow-orange);
		}

		&:hover {
			cursor: pointer;
			color: var(--yellow-orange);
		}
	}
}

@media (--tablet) {
	a.subMenuItemTitle {
		margin-right: 17px;
		color: var(--white);
		text-decoration: none;
	}

	.subMenuItem {
		&.active {
			margin-right: 0;
			list-style-position: inside;
			direction: rtl;
			list-style-type: disc;
			color: var(--yellow-orange);

			&[data-toggled='unToggled'] {
				.subMenuItemTitle {
					color: var(--yellow-orange);
				}
			}

			&[data-toggled='toggled'] {
				.subMenuItemTitle {
					margin-right: 0;
					color: var(--white);
				}
			}
		}
	}
}
