.footer {
	background-color: var(--sea-header);

	.logoBlock {
		padding: 56px 0 40px;

		img {
			width: auto;
			height: 40px;
		}

		.logoBlockHref {
			display: block;
			height: 40px;
			position: relative;
			width: 165px;
		}
		.image {
			object-fit: 'contain';
		}
	}

	.contactHeader {
		padding-top: 56px;
		padding-bottom: 62px;
		display: flex;
		justify-content: space-between;
		flex-direction: row;

		> div {
			width: calc(50% - 20px);
		}
	}

	.contactHeading {
		margin: 0 0 24px;
		color: white;
		line-height: 1.25;
	}

	.footerBottom {
		background-color: var(--blue-green);
	}

	@media (--mobile) {
		.contactHeader {
			flex-direction: column;

			> div {
				width: 100%;
			}
		}

		.contactHeading {
			margin-bottom: 20px;
		}

		.logoBlock {
			padding: 40px 0 48px;
		}

		.footerBottom {
			padding-bottom: 24px;
		}
	}

	@media (--desktop) {
		.contactHeader {
			padding-top: 40px;
			padding-bottom: 44px;
		}
	}
}
