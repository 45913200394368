.logo {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	.logoContainer {
		height: 40px;
		position: relative;
		width: 175px;

		@media (--mobile) {
			height: 30px;
			width: 122px;
		}
	}

	.mobileLogoContainer {
		height: 27px;
		position: relative;
		width: 27px;
	}
}
