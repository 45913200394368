.mainMenuContainer {
	.subMenu,
	.activeItem {
		display: none;
	}
}

.logo {
	div[data-id='mobile-logo'] {
		display: none;
	}
}

@media (--tablet) {
	.mainMenuContainer {
		&[data-toggled='unToggled'] {
			margin-left: 24px;
			justify-content: space-between;
			align-items: center;
			.close {
				display: flex;
				align-items: center;
			}

			.activeItem {
				display: flex;
				min-width: 100px;
				a {
					color: var(--yellow-orange);
				}
			}
		}
		&[data-toggled='toggled'] {
			overflow: scroll;
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			backdrop-filter: blur(8px);
			background-color: rgba(61, 155, 139, 0.85);
			display: flex;
			flex-direction: column;
			z-index: 100;

			.subMenu {
				padding: 1.5em;
				margin-top: 21px;
				display: inline;
				z-index: 100;
			}
		}
	}

	.logo[data-toggled='toggled'] div[data-id='mobile-logo'] {
		display: flex;
		position: fixed;
		z-index: 101;
		top: 15px;
		left: 15px;
	}

	.logo[data-toggled='toggled'] div[data-id='logo'] {
		display: none;
	}
}
