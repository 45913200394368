.contactItems {
	margin-bottom: 0;
	display: flex;
	max-width: 500px;
	justify-content: space-between;
}

.contactItem {
	display: flex;
	color: white;
	@mixin fontFamily font-body;
	font-size: 0.875rem;
	line-height: 1.54;
	flex-direction: column;
	align-items: center;
	flex: 1 0 0;

	a {
		display: flex;
		width: 72px;
		height: 72px;
		margin-bottom: 10px;
		background-color: #18695a;
		border-radius: var(--border-radius);
		align-items: center;
		justify-content: center;

		img {
			max-width: 40px;
			max-height: 40px;
		}
	}

	&:first-child {
		align-items: flex-start;
	}

	&:last-child {
		align-items: flex-end;
	}
}

@media (--mobile) {
	.contactItems {
		margin-bottom: 40px;
	}

	.contactItem {
		a {
			margin-bottom: 0;
		}

		span {
			display: none;
		}
	}
}
