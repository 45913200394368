.subMenuContainer {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
	border-top: 1px solid var(--very-light-pink);
	border-bottom: 1px solid var(--very-light-pink);

	.subMenuMobileHeader {
		display: none;
	}

	.subMenu {
		padding-top: 10px;
		padding-bottom: 10px;

		.subMenuList {
			display: flex;
			list-style-type: none;
			margin: 0;
		}

		.subMenuList li:last-child {
			margin-right: 0;
		}
	}
}

@media (--tablet) {
	.subMenuContainer[data-toggled='unToggled'] {
		display: none;
	}
	.subMenuContainer[data-toggled='toggled'] {
		display: flex;
		border: none;
		flex-direction: column;
		align-items: flex-end;

		.subMenuMobileHeader {
			display: block;
			margin-bottom: 20px;
			@mixin fontFamily font-heading;
			font-weight: 400;
			font-size: 0.8rem;
			color: var(--sea-light);
			margin-right: 17px;
			text-transform: uppercase;
		}

		.subMenuList {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-content: flex-start;
			list-style: none;
			margin: 0;
			padding: 0;
			align-items: flex-end;

			li {
				margin-bottom: 20px;
				margin-right: 0;
				color: var(--white);
			}
		}
	}
}
