.contactItems {
	margin-bottom: 0;
	max-width: 500px;
	list-style: none;

	.contactItem {
		margin-bottom: 24px;
		height: 20px;
		@mixin fontFamily font-heading;
		font-weight: 400;
		font-size: 0.875rem;

		a {
			display: flex;
			align-items: center;
			color: var(--white);
			text-decoration: none;
			position: relative;

			div {
				display: inline-block;
				height: 20px;
				margin-right: 12px;
				position: relative;
				width: 20px;
			}
		}
	}
	.contactImage {
		object-fit: contain;
	}
}

@media (--mobile) {
	.contactItems {
		margin-bottom: 42px;
	}
}
