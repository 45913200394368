.container {
	max-width: 80em;
	margin-right: auto;
	margin-left: auto;
	padding: 0 40px;

	@mixin addStylingFor zvr, max-width, $breakpointXl;
	@mixin addStylingFor zvr, padding, 0;
}

.fullWidth {
	max-width: 100%;
	padding: 0 16px;
}

.withSpacing {
	padding-left: 190px;
	padding-right: 190px;
}

.row {
	display: flex;
	flex-direction: row;
}

.column {
	display: flex;
	flex-direction: column;
}

@media (--desktop) {
	.container {
		@mixin addStylingFor zvr, padding, 0 16px;
	}
}

@media (--tablet) {
	.withSpacing {
		padding-left: 60px;
		padding-right: 60px;
	}
}

@media (--mobile) {
	.container {
		padding: 0 16px;
	}

	.withSpacing {
		padding: 0;
	}

	.fullWidth {
		padding: 0 8px;
	}

	.row {
		display: flex;
		flex-direction: column;
	}
}
