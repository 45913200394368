.menu {
	display: flex;
	flex-direction: row;

	ul {
		display: flex;
		list-style-type: none;
		margin: 0;

		li:last-child {
			margin: 0;
			padding-right: 0;
			a {
				padding-right: 0;
			}
		}
	}
}

@media (--tablet) {
	.menuContainer {
		&[data-toggled='toggled'] {
			padding: 1.5em;
			display: inline;
			z-index: 100;
			margin-right: 17px;

			.menu {
				display: inline;
			}

			.mainMenuList {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				align-content: flex-start;
				list-style: none;
				margin: 0;
				padding: 0;
				align-items: flex-end;

				li {
					text-align: right;
				}
			}
		}
	}
}
